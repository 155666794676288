import "./application";

//
//
// FANCYBOX
import { fancybox } from "components/fancybox";


jQuery(document).ready(function($){
  //
  // POPOVER
  $('[data-toggle="popover"]').popover({ placement: 'auto', html: true, trigger: 'focus' })
  //
  //
  // SHRINK HEADER
  $(window).on('load scroll resize orientationchange', function () {
    if ($(document).scrollTop()) {
      $("#top_bar").addClass("fixed-top");
      $('.headerimage').css({ 'padding-top': $('#top_bar').height()+'px' });
    } else {
      $("#top_bar").removeClass("fixed-top");
      $('.headerimage').css({ 'padding-top': '0px' });
    }
  });
});